import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../Context/AuthContext'
import { ElectionContextProvider } from '../Context/ElectionContext'


export default function UserLayOut() {
  return (
    <>
    <AuthProvider>
        <ElectionContextProvider>
            <Outlet />
         </ElectionContextProvider>
    </AuthProvider>
    </>
  )
}