import React from 'react';
import { Route, Redirect, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "./AuthContext";


const PublicRoute = ({restricted}) => {
const { currentUser,ufrom } = useAuth();

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route

        currentUser && restricted ?
            <Navigate to={ufrom || "/dashboard"} />
            : <Outlet/>
        
    );
};

export default PublicRoute;